<template>
  <div>
    <h2>
      {{CATEGORY.name}} {{ titleComplement }}<slot name="tab-head-commands"></slot>
    </h2>
    <detailed-competitors-list
        :showName = 'true'
        :showRollnumber = "COMPETITION.COMPETITION_TYPE.isFederal && !editingCompetitorsWithMembers"
        :showMembers = "editingCompetitorsWithMembers"
        :showMembersRollNumber = "COMPETITION.COMPETITION_TYPE.isFederal"
        :showIsOpen="COMPETITION.isOpen"
        :items = 'items'
        :canAdd = "!editingCompetitorsCategoryIsStarted && !isLockedForRegistration"
        :canWithdraw = "registrationMode=='S' || registrationMode=='C'"
        :canEdit = "true"
        :canRemove = "!editingCompetitorsCategoryIsStarted && !isLockedForRegistration"
        :canGiveUp = "!editingCompetitorsCategoryIsStarted"
        :canChangePresence = "!editingCompetitorsCategoryIsStarted"
        @add = 'addCompetitor'
        @edit = 'editCompetitor'
        @editLicensee = 'editCompetitorLicensee'
        @remove = 'removeCompetitor'
        @isOpenStatus_change = 'competitorOpenStatusChanged'
        @withdrawState_change = 'competitorWithdrawChanged'
        @forfeitState_change = 'competitorForfeitChanged'
        @presenceState_change = 'competitorPresenceChanged'
        ref="lstCompetitors2"
    >
      <template #headCommandLine>
        <slot name="listHeadCommandLine"></slot>
      </template>
    </detailed-competitors-list>

    <!-- Dialogue d'ajout d'un compétiteur individuel -->
    <individual-competitor-edit-dialog v-if="CATEGORY && CATEGORY.isIndividual"
      :competition_id = "competition_id"
      :level = "level"
      :category = "category"
      :competitor_id = "selected_competitor_id"
      :creationMode = "competitorDialog_creationMode"
      :search_suggestions = "competitorDialog_suggestions"
      v-model = "showAddEditCompetitorDialog"
      @hide="showAddEditCompetitorDialog = false"
      @ok="addEditCompetitorDialog_ok"
      @competitorCreated="competitorsListChange"
    >
    </individual-competitor-edit-dialog>

    <!-- Dialogue d'ajout d'une paire -->
    <team-competitor-edit-dialog v-if="CATEGORY && CATEGORY.isPair"
      :minMemberCount="2"
      :maxMemberCount="2"
      :competition_id = "competition_id"
      :level = "level"
      :category = "category"
      :competitor_id = "selected_competitor_id"
      :members = "selected_competitor_members"
      :creationMode = "competitorDialog_creationMode"
      :search_suggestions = "competitorDialog_suggestions"
      v-model = "showAddEditCompetitorDialog"
      @hide="showAddEditCompetitorDialog = false"
      @ok="addEditCompetitorDialog_ok"
      @competitorCreated="competitorsListChange"
    >
    </team-competitor-edit-dialog>

    <!-- Dialogue d'ajout d'une équipe -->
    <team-competitor-edit-dialog v-if="CATEGORY && CATEGORY.isTeam"
      :minMemberCount="3"
      :maxMemberCount="8"
      :competition_id = "competition_id"
      :level = "level"
      :category = "category"
      :competitor_id = "selected_competitor_id"
      :members= "selected_competitor_members"
      :creationMode = "competitorDialog_creationMode"
      :search_suggestions = "competitorDialog_suggestions"
      v-model = "showAddEditCompetitorDialog"
      @hide="showAddEditCompetitorDialog = false"
      @ok="addEditCompetitorDialog_ok"
      @competitorCreated="competitorsListChange"
    >
    </team-competitor-edit-dialog>

    <!-- Dialogue de traitement de l'état de validité de la license d'un pilote -->
    <licensee-edit-dialog
      ref="licenceDialog"
      v-model="showEditLicenseeDialog"
      @hide="showEditLicenseeDialog = false"
      @ok="editLicenseeDialog_ok"
      :federalNumber="selectedLicensee"
      :year="selectedLicenceYear"
      :peopleName="selectedLicencePeopleName"
    >
    </licensee-edit-dialog>
  </div>
</template>

<script>
import { RST_COMPETITOR, REF_CATEGORY, RST_COMPETITION, COMPETITOR, PEOPLE } from "@/LocalDB";
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import RoundsConfigurationHelper from "@/services/helpers/roundsConfigurationHelper";
import DetailedCompetitorsList from "@/components/DetailedCompetitorsList";
import IndividualCompetitorEditDialog from "./IndividualCompetitorEditDialog.vue";
import TeamCompetitorEditDialog from './TeamCompetitorEditDialog.vue';
//import TeamCompetitorEditDialog from './TeamCompetitorEditDialog.vue';
import LicenseeEditDialog from './LicenseeEditDialog.vue';

export default{
  props:{
    competition_id: { type:String, required: true },
    level: { type:String, required: true},
    category: { type:String, required: true},
    lockedForRegistration: { type: Boolean, required:true }
  },
  components:{ DetailedCompetitorsList, IndividualCompetitorEditDialog, TeamCompetitorEditDialog, LicenseeEditDialog },
  data(){
    return {
      VERBOSE: false,

      items: [],
      showAddEditCompetitorDialog: false,
      selected_competitor_id: null,
      selected_competitor_members: [],
      competitorDialog_creationMode: false,
      competitorDialog_suggestions: [],

      showEditLicenseeDialog: false,
      selectedLicensee: '',
      selectedLicencePeopleName: '',
      selectedLicenceYear: null,

      editingCompetitorsWithMembers:false,

      isLockedForRegistration: this.lockedForRegistration, 
    };
  },
  computed:{
    editingCompetitorsCategoryIsStarted(){
      return RoundsConfigurationHelper.isCategoryStarted(this.competition_id, this.level, this.category);
    },
    isForSimulationPurposeOnly(){
      return true;
    },
    COMPETITION(){
      return RST_COMPETITION.query().where('id', this.competition_id).first();
    },
    CATEGORY(){
      return REF_CATEGORY.query().where('code', this.category).first();
    },
    registrationMode(){
      return this.COMPETITION.registrationMode;
    },
    titleComplement(){
      const registrationMode = this.COMPETITION.registrationMode;
      switch(registrationMode){
        case "S":
          return " sélectionnés";
        case "R":
          return " inscrits";
        case "A":
          return " pré-inscrits";
      }
      return "";
    }
  },
  methods:{
    async refresh(){
      await this.$showRefresher();
      if(this.VERBOSE) console.log('refresh for ', this.competition_id, this.level, this.category);
      var ok = await CompetitionsConfigurationHelper.refreshLevelCategoryCompetitors(this.competition_id, this.level, this.category);
      this.editingCompetitorsWithMembers = !this.CATEGORY.isIndividual;
      this.items = RST_COMPETITOR.query()
          .where("competition_id", this.competition_id)
          .where("level", this.level)
          .where("category", this.category)
          .where("round_number", null)
          .orderBy("subscription_order")
          .get()
          .map(mapped => {
            var hasAtLeastOneNote = mapped.hasAtLeastOneNote();
            var competitor = mapped.COMPETITOR;
            var people = competitor.isIndividual ? competitor.PEOPLE : null;
            if(competitor.isIndividual && people == null)
            {
              people = PEOPLE.query().where('id', competitor.people_id).first();
              if(people == null)
                console.log('pb. competitor/people', {mapped: mapped, competitor: competitor, people: people});
            }
            var licensee = people != null ? people.LICENSEE : null;
            //console.log('people/licensse', people, licensee);
            var members = this.editingCompetitorsWithMembers 
                        ? mapped.MEMBERS.map(m => { var id = m.id;
                            var people_id = m.people_id;
                             return { ...m, ...m.PEOPLE, LICENSEE: m.LICENSEE, id: id, people_id: people_id }})
                        : null
            var complement = {
              isOpen: mapped.isOpen,
              canChangeIsOpenStatus: !hasAtLeastOneNote,
              canWithdraw : !hasAtLeastOneNote,
              canBeRemoved : !hasAtLeastOneNote,
              canGiveUp : !hasAtLeastOneNote,
              canChangePresence : !hasAtLeastOneNote,
              canDelete : !hasAtLeastOneNote,
              canEdit : true,
              firstname: competitor.isIndividual ? people.firstname : null,
              lastname: competitor.isIndividual ? people.lastname : null,
              federal_number : competitor.isIndividual ? mapped.COMPETITOR.PEOPLE.federal_number : null,
              isWithdrawn: mapped.isWithdrawn,
              isAbsent: mapped.isAbsent,
              isForfeited: mapped.isForfeited,
              PEOPLE: people,
              LICENSEE: licensee,
              members: members,
            };
            return { ...competitor, ...complement};
          });
          if(this.VERBOSE) console.log('refresh -> items', this.items);
      this.$emit('dataSynchronisationStateChange', ok);
      this.$hideRefresher();
    },
    addCompetitor(){
      this.competitorDialog_creationMode = true;
      this.selected_competitor_id = null;
            if(this.CATEGORY.isIndividual) {
              this.competitorDialog_suggestions = COMPETITOR.query()
                    .where('competition_type', this.COMPETITION.competition_type)
                    .where('category', this.category)
                    .where('isIndividual', true)
                    .get()
                    .filter(item => {
                      return !RST_COMPETITOR.query()
                                .where("competition_id", this.competition_id)
                                .where('category', this.category)
                                .where('level', this.level)
                                .where('competitor_id', item.id)
                                .exists();
                    })
                    .map(item => {
                        var mapped = item;
                        mapped.matches = '**';
                        mapped.forSuggestion = '** ' + item.name + ((item.isFederal == true) ?  ' - ' + item.PEOPLE.federal_number : '');
                        return mapped;
                    });
                COMPETITOR.query()
                    .where('competition_type', this.COMPETITION.competition_type)
                    .where(item => item.category != this.category)
                    .where('isIndividual', true)
                    .get()
                    .forEach(comp => {
                        if(this.competitorDialog_suggestions.filter(item => item.people_id == comp.people_id).length == 0)
                        {
                          if(!RST_COMPETITOR.query()
                                .where("competition_id", this.competition_id)
                                .where('category', this.category)
                                .where(element => element.COMPETITOR.people_id == comp.people_id)
                                .exists()){
                              var mapped = comp;
                              mapped.id = null;
                              mapped.matches = "*";
                              mapped.forSuggestion = '* ' + comp.name + ((comp.isFederal == true) ? ' - ' + comp.PEOPLE.federal_number : '');
                              this.competitorDialog_suggestions.push(mapped);
                          }
                        }
                    });
                PEOPLE.query()
                    .get()
                    .forEach(people => {
                        if(this.competitorDialog_suggestions.filter(item => item.people_id == people.id).length == 0)
                        {
                          if(!RST_COMPETITOR.query()
                                .where("competition_id", this.competition_id)
                                .where('category', this.category)
                                .where(element => element.COMPETITOR.people_id == people.id)
                                .exists()){
                              var mapped = {
                                  id: null,
                                  name: people.name,
                                  shortname: people.shortname,
                                  //category: this.editingCompetitorsCategory.code,
                                  //competition_type: this.currentCompetition.competition_type,
                                  federal_number: people.federalNumber,
                                  firstname: people.firstname,
                                  lastname: people.lastname,
                                  sexe: people.sexe,
                                  people_id: people.id,
                                  isIndividual: true,
                                  PEOPLE: people,
                                  matches: '',
                                  forSuggestion: people.name + ((people.isFederal == true) ? ' - ' + people.federalNumber : ''),
                              }
                              this.competitorDialog_suggestions.push(mapped);
                          }
                        }
                    });
                    this.competitorDialog_suggestions.sort(function (a,b) {
                      var ret = b.matches.length - a.matches.length;
                      if(ret == 0)
                        ret = a.name.localeCompare(b.name);
                      return ret;
                    });
                    //console.log(this.competitorDialog_suggestions);
              }else{
                this.competitorDialog_suggestions = COMPETITOR.query()
                    .where('competition_type', this.COMPETITION.competition_type)
                    .where('category', this.category)
                    .get()
//                    .map(item => item.name)
                    ;
                
            }
      this.showAddEditCompetitorDialog = true;
    },
    editCompetitor(competitor){

      this.competitorDialog_creationMode = false;
      this.selected_competitor_id = competitor;
      this.selected_competitor_members = competitor.members && Array.isArray(competitor.members) ? competitor.members.map(a => a) : [];
      if(this.VERBOSE) console.log('editCompetitor', this.selected_competitor_id, this.selected_competitor_members, );
      this.showAddEditCompetitorDialog = true;
    },
    editCompetitorLicensee(competitor){
      this.selected_competitor_id = competitor;
      var people = PEOPLE.query().where("id", competitor.people_id).first();
      this.selectedLicensee = people.federalNumber;
      this.selectedLicencePeopleName= people.name;
      this.selectedLicenceYear = this.COMPETITION.year;
      this.showEditLicenseeDialog = true;
    },
    async removeCompetitor(competitor){
      this.$showSaver();
      var success = await CompetitionsConfigurationHelper.dettach_competitor_Async(this.competition_id, competitor.id, this.level, this.category);
      this.toastAsyncSaved(success, 'Compétiteur supprimé de la compétition.');
      this.$hideSaver();
      await this.refresh();
      this.competitorsListChange();
    },
    competitorsListChange(){
      console.log('competitorsListChange');
      this.$emit('competitorsListChange');
    },
    async competitorOpenStatusChanged(competitor){
      await this.showSaver();
      var success = await CompetitionsConfigurationHelper.changeCompetitorIsOpenStatusAsync(this.competition_id, competitor.id, competitor.isOpen);
      this.toastAsyncSaved(success, competitor.isOpen ? 'Compétiteur marqué comme participant en mode "open"' : 'Compétiteur marqué pour concourrir en mode "classé"');
      this.hideSaver();
      await this.refresh();
      this.presentCompetitorsChange();
   },
    async competitorWithdrawChanged(competitor){
      await this.$showSaver()
      var success = await CompetitionsConfigurationHelper.changeCompetitor_withdraw_Async(this.competition_id, competitor.id, competitor.isWithdrawn);
      this.toastAsyncSaved(success, competitor.isWithdrawn ? 'Compétiteur marqué comme "désisté".' : 'Compétiteur marqué comme "inscrit".');
      this.$hideSaver();
      await this.refresh();
      this.presentCompetitorsChange();
    },
    async competitorForfeitChanged(competitor){
      await this.$showSaver()
      var success = await CompetitionsConfigurationHelper.changeCompetitor_forfeit_Async(this.competition_id, competitor.id, competitor.isForfeited);
      this.toastAsyncSaved(success, competitor.isForfeited ? 'Compétiteur marqué comme "Forfait".' : 'Compétiteur marqué comme "Participant".');
      this.$hideSaver();
      await this.refresh();
      this.presentCompetitorsChange();
    },
    async competitorPresenceChanged(competitor){
      console.log('competitorPresenceChanged', competitor);
      await this.$showSaver()
      var success = await CompetitionsConfigurationHelper.changeCompetitor_presence_Async(this.competition_id, competitor.id, competitor.isAbsent);
      this.toastAsyncSaved(success, competitor.isAbsent ? 'Compétiteur marqué comme "Absent".' : 'Compétiteur marqué comme "Présent".');
      this.$hideSaver();
      await this.refresh();
      this.presentCompetitorsChange();
    },
    presentCompetitorsChange(){
      this.$emit('presentCompetitorsChange');
    },
    async addEditCompetitorDialog_ok(){
      await this.refresh();
    },
    async editLicenseeDialog_ok(){
      await this.refresh();
    }
  },
  async mounted(){
    await this.refresh();
  },
  watch:{
    lockedForRegistration: function(newValue){
      this.isLockedForRegistration = newValue;
    }
  }
}
</script>
